import React from "react";
import {
  Route,
  createBrowserRouter,
  createRoutesFromElements,
  RouterProvider,
  Outlet,
} from "react-router-dom";
import ViewSession from "./components/view-session/ViewSession";
import ViewCinema from "./components/view-session/3dViews/Cinema";
import Home from "./components/home/home";
import "./App.css";
import Navigation from "./layout/navigation";
import Banner from "./layout/banner";
import { StreamProvider } from "./context/StreamContext";
import ErrorBoundary from "./layout/ErrorBoundary";

function App() {
  const router = createBrowserRouter(
    createRoutesFromElements(
      <Route path="/" element={<Root />} errorElement={<ErrorBoundary />}>
        <Route index element={<Home />} />
        <Route exact path="/view" element={<ViewSession />} />
        {/* <Route exact path="/view/default" element={<Default />} /> */}
        <Route exact path="/view/cinema" element={<ViewCinema />} />
      </Route>
    )
  );

  return (
    <div className="App">
      <StreamProvider>
        <RouterProvider router={router} />
      </StreamProvider>
    </div>
  );
}

const Root = () => {
  return (
    <>
      {window.location.pathname === "/" && <Navigation />}
      {window.location.pathname.match(/view\/.*[a-z]/i) === null && <Banner />}
      <Outlet />
    </>
  );
};

export default App;
